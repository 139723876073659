<template>
  <svg
    v-if="show"
    id="logo"
    width="119"
    height="40"
    viewBox="0 0 119 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_2)">
      <path v-if="country === 'kz'" d="M100.126 14H97V26.2428H100.126V14Z" :fill="fill" />
      <path
        v-if="country === 'kz'"
        d="M100 20.1316L105.5 14H109.576L103.917 20.1316L109.556 26.2428H105.619L100 20.1316Z"
        :fill="fill"
      />
      <path
        v-if="country === 'kz'"
        d="M115.5 16.5521H109L111 14H119V16.5521L112.5 23.6979H119V26.25H109V23.6979L115.5 16.5521Z"
        :fill="fill"
      />
      <path
        v-if="country === 'ru'"
        d="M100.31 14.0331V15.5968C100.844 14.6092 102.051 13.9302 103.416 13.9302H104.86V17.0166H103.396C101.378 17.0166 100.329 18.354 100.329 20.3088V26.2759H97.2034V14.0331H100.31Z"
        :fill="fill"
      />
      <path
        v-if="country === 'ru'"
        d="M106.938 21.399V14.0327H110.064V20.8228C110.064 22.5718 110.954 23.6418 112.735 23.6418C114.238 23.6418 115.584 22.3866 115.584 20.2673V14.0327H118.71V26.2755H115.584V25.1438C114.911 25.8846 113.665 26.6253 112.082 26.6253C109.055 26.6253 106.938 24.6294 106.938 21.399Z"
        :fill="fill"
      />
      <path d="M30.0335 13.8682H26.9075V26.111H30.0335V13.8682Z" :fill="fill" />
      <path
        d="M29.5981 19.9998L35.0984 13.8682H39.1741L33.5156 19.9998L39.1543 26.111H35.2171L29.5981 19.9998Z"
        :fill="fill"
      />
      <path
        d="M45.2679 13.5391C42.7552 13.5391 40.3019 14.7736 39.4907 17.4691H42.7948C43.3092 16.6255 44.2391 16.2345 45.3866 16.2345C46.9299 16.2345 48.0972 16.9959 48.0972 18.4156H45.1888C42.4387 18.4156 39.6292 19.2181 39.6292 22.3045C39.6292 24.9588 41.3703 26.4609 44.2589 26.4609C45.7626 26.4609 47.2069 25.8847 48.0774 24.9794V26.1111H51.2034V18.5185C51.2232 15.3292 48.8094 13.5391 45.2679 13.5391ZM48.0972 21.3374C48.0972 22.9835 46.5935 23.8477 44.892 23.8477C43.5071 23.8477 42.7552 23.1687 42.7552 22.3251C42.7552 21.3168 43.6653 20.823 45.1888 20.823H48.0972V21.3374Z"
        :fill="fill"
      />
      <path
        d="M61.5708 17.469C61.0959 16.5637 60.1067 16.2139 58.7019 16.2139C57.3368 16.2139 56.5454 16.6871 56.5454 17.4279C56.5454 18.1275 57.2181 18.4155 58.4249 18.5184L60.5617 18.7036C63.2327 18.9299 64.7957 20.0411 64.7957 22.4073C64.7957 24.7736 62.7579 26.4608 59.2955 26.4608C55.7936 26.4608 53.637 24.9382 53.0632 22.2633H56.3871C56.763 23.3127 57.871 23.7859 59.5329 23.7859C60.72 23.7859 61.6697 23.4155 61.6697 22.6336C61.6697 21.9341 61.0168 21.6048 59.8297 21.4814L57.4951 21.255C54.8241 20.967 53.3996 19.8559 53.3996 17.6131C53.3996 15.2468 55.4176 13.5596 58.7613 13.5596C62.3028 13.5596 64.2219 15.1234 64.8946 17.5102H61.5708V17.469Z"
        :fill="fill"
      />
      <path
        d="M75.0246 17.469C74.5498 16.5637 73.5605 16.2139 72.1558 16.2139C70.7906 16.2139 69.9992 16.6871 69.9992 17.4279C69.9992 18.1275 70.6719 18.4155 71.8788 18.5184L74.0156 18.7036C76.6865 18.9299 78.2496 20.0411 78.2496 22.4073C78.2496 24.7736 76.2117 26.4608 72.7493 26.4608C69.2474 26.4608 67.0909 24.9382 66.5171 22.2633H69.841C70.2169 23.3127 71.3248 23.7859 72.9868 23.7859C74.1739 23.7859 75.1235 23.4155 75.1235 22.6336C75.1235 21.9341 74.4706 21.6048 73.2835 21.4814L70.9489 21.255C68.2779 20.967 66.8534 19.8559 66.8534 17.6131C66.8534 15.2468 68.8715 13.5596 72.2151 13.5596C75.7567 13.5596 77.6758 15.1234 78.3485 17.5102H75.0246V17.469Z"
        :fill="fill"
      />
      <path
        d="M89.2893 14.0327L85.728 21.9134L82.226 14.0327H78.7637L83.9473 25.8434L81.3159 31.543H84.719L92.6725 14.0327H89.2893Z"
        :fill="fill"
      />
      <path
        d="M94.6709 24.3624C94.6709 23.2101 93.7806 22.2842 92.6726 22.2842C91.5646 22.2842 90.6743 23.2101 90.6743 24.3624C90.6743 25.5146 91.5646 26.4406 92.6726 26.4406C93.7806 26.4406 94.6709 25.5146 94.6709 24.3624Z"
        :fill="fill"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.231 10C19.231 4.4856 14.9179 0 9.61549 0C4.31312 0 0 4.4856 0 10V20V30C0 35.535 4.31312 40 9.61549 40C14.9113 40 19.2203 35.5461 19.231 30.0206C19.231 30.0159 19.231 30.0111 19.231 30.0064C19.231 30.0042 19.231 30.0021 19.231 30V10Z"
        fill="#8F4BE0"
      />
      <path
        d="M0 19.9914L9.61552 30.0017L19.2282 30.0023L9.61552 20.0017L19.2282 10L9.61552 10.0017L0 19.9914Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="119" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useTheme } from 'vuetify'

const vTheme = useTheme()

const show = ref(true)
const country = ref('ru')

const fill = computed(() => {
  return vTheme.current['value'].colors['logo-color']
})

const domain = import.meta.env.KASSY_DOMAIN
const checkDomain = () => (domain === 'kassy.kz' ? (country.value = 'kz') : (country.value = 'ru'))

onBeforeMount(async () => {
  checkDomain()
})
</script>

<style scoped>
svg {
  vertical-align: center;
}
</style>

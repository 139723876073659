import type { LocalStorageCities, CityFlatten } from './types'
import type { GeoSubdivision } from '@/shared/api/geo_subdivision/types'

export const getLocalStorageCities = (): LocalStorageCities => {
  const dataString = localStorage.getItem('kassy_cities') ?? null
  if (dataString) {
    const data = JSON.parse(dataString)
    if (isLocalStorageCities(data)) {
      return data
    }
  }
  return <LocalStorageCities>{}
}

export const isLocalStorageCities = (data: LocalStorageCities) => {
  return Array.isArray(data.cities)
}

export const setLocalStorageCities = (data: LocalStorageCities) => {
  localStorage.setItem('kassy_cities', JSON.stringify(data))
}

export const getCitiesFlatten = (): CityFlatten[] => {
  const flatCities: CityFlatten[] = []

  function flatten(city: GeoSubdivision) {
    /*
    flatCities.push({
      id: city.id,
      region: city.region,
      city: city.city,
      url: city.url,
      geo_lat: city.geo_lat,
      geo_lng: city.geo_lng,
      keywords: city.keywords
    })
    */
    flatCities.push(<CityFlatten>city)
    city.cities?.map((childCity) => flatten(<GeoSubdivision>childCity))
  }
  const data = getLocalStorageCities()
  data.cities.map((city) => flatten(city))

  return flatCities
}

<template>
  <v-btn
    id="toggle_theme"
    variant="plain"
    rounded="xl"
    color="logo-color"
    @click="
      // prettier-ignore
      toggleThemeByButton = true;
      toggleTheme(toggledThemeId)
    "
  >
    <v-icon color="logo-color">
      {{ themes[themes.map((x) => x.id).indexOf(toggledThemeId)].icon }}
    </v-icon>
  </v-btn>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { viewportHandler } from '@/shared/helpers/viewportHandler'
import { useTheme } from 'vuetify'
const currentTheme = useTheme()

const urlParams = new URLSearchParams(window.location.search)

const themeParam = urlParams.get('theme')

onBeforeMount(async () => {
  initTheme()
})

const themes = [
  {
    id: 'light',
    theme: 'kassyLightTheme',
    icon: '$weatherSunny'
  },
  {
    id: 'dark',
    theme: 'kassyDarkTheme',
    icon: '$weatherNight'
  },
  {
    id: 'system',
    theme: '',
    icon: '$laptop'
  }
]

type IPrivilegedThemeNames = {
  [id: string]: string
}

const privilegedThemeNames: IPrivilegedThemeNames = themes.reduce(
  (obj, item) => ({ ...obj, [item.id]: item.theme }),
  {}
)

let toggledThemeId = ref('')

const mq = window.matchMedia('(prefers-color-scheme: dark)')

const initTheme = () => {
  // читаем, если пусто, то system
  toggledThemeId.value = localStorage.getItem('kassy_theme') ?? 'system'
  if (themeParam && themeParam in privilegedThemeNames) {
    toggleTheme((toggledThemeId.value = themeParam))
  }
  toggleTheme(toggledThemeId.value)
  // назначаем обработчик для отлова изменения системной темы
  mq.addEventListener('change', toggleThemeWatchSystem)
}

const toggleThemeWatchSystem = () => {
  if (toggledThemeId.value === 'system') {
    currentTheme.global.name.value = mq.matches ? 'kassyDarkTheme' : 'kassyLightTheme'
    // вызываем пересчет высоты
    viewportHandler()
  }
}

const toggleThemeByButton = ref(false)

const toggleTheme = (id: string) => {
  toggledThemeId.value = id in privilegedThemeNames ? id : 'system'

  if (toggleThemeByButton.value) {
    themes.forEach((e, i, arr) => {
      i++
      if (i >= arr.length) {
        i = 0
      }
      if (e.id === id) {
        toggledThemeId.value = arr[i].id
      }
    })
  }

  if (toggledThemeId.value === 'system') {
    toggleThemeWatchSystem()
  } else {
    currentTheme.global.name.value = privilegedThemeNames[toggledThemeId.value]
  }
  // вызываем пересчет высоты
  viewportHandler()
  // сохраняем
  localStorage.setItem('kassy_theme', toggledThemeId.value)
}
</script>

type Options = {
  action: string
  category?: string
  label?: string
  value?: string
}

export const useAnalytics = (options: Options) => {
  const { action, category, label, value } = options
  const values = {
    event_category: category ? category : undefined,
    event_label: label ? label : undefined,
    value: value ? value : undefined
  }

  if (window.gtag) {
    window.gtag('event', action, values)
  }

  if (window.ym) {
    const metrikaID: number = +import.meta.env.KASSY_YANDEX_METRIKA_ID
    window.ym(metrikaID, 'reachGoal', action, values, () => {
      //   console.log('Событие', action, 'успешно отправлено')
    })
  }
}

import { sendRequest, calculateSign } from '@/shared/api/HttpRequest'

export const getGeoSubdivisions = async (url: string, agent_id: string, secret_key: string) => {
  let xml = `<?xml version="1.0" encoding="utf-8"?>
        <request module="geo_subdivision" format="json">
            <auth id="${agent_id}" />
        </request>`

  xml = xml.replace(/(\r\n|\n|\r)/gm, '').replace(/>(\s*)</gm, '><')
  const sign = calculateSign(xml, secret_key)
  const response = await sendRequest(url, xml, sign)
  return {
    result: response.data.result,
    content: response.data.content
  }
}

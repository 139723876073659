import { createApp } from 'vue'
import '@/assets/styles/main.css'

import App from './App.vue'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import {
  mdiLaptop,
  mdiWeatherNight,
  mdiWeatherSunny,
  mdiClose,
  mdiPhone,
  mdiMapMarker
} from '@mdi/js'

import { kassyLightTheme, kassyDarkTheme } from '@/shared/api/local_storage/storageTheme'
import * as Sentry from '@sentry/vue'
import { detect } from 'detect-browser'

export const browser = detect()

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      laptop: mdiLaptop,
      weatherSunny: mdiWeatherSunny,
      weatherNight: mdiWeatherNight,
      close: mdiClose,
      phone: mdiPhone,
      mapMarker: mdiMapMarker
    },
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'kassyLightTheme',
    themes: {
      kassyLightTheme,
      kassyDarkTheme
    }
  }
})

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://b34d9e8d8a1a5440305bdf73579b1766@sentry.kassy.ru/7',
  release: 'main-page-kassy@' + import.meta.env.KASSY_APP_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(vuetify).mount('#app')

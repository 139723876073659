<template>
  <v-card
    min-width="320"
    class="d-flex flex-column flex-grow-1 justify-space-between v-card--variant-flat card-container"
    color="transparent"
  >
    <div class="search_wrap">
      <v-card-text class="search">
        <v-text-field
          color="#8f4ae0"
          v-model="search"
          variant="underlined"
          hide-details="auto"
          :clearable="true"
          placeholder="введите название города"
          clear-icon="$close"
          maxlength="25"
          @click:clear="clearCity"
          :disabled.prop="inputDisabled"
          :focused="inputDisabled"
          @blur="
            useAnalytics({
              action: 'city-search',
              category: 'category',
              label: 'label',
              value: '123'
            })
          "
          @focus="scrollWrapToTop"
          @input="
            // prettier-ignore
            checkNotFound();
            scrollWrapToTop()
          "
        >
          <template v-slot:prepend-inner>
            <logo-kassy
              @mousedown.stop
              @click.stop.prevent="scrollWrapToTop"
              class="logo-kassy pointer"
            />
          </template>
          <template v-slot:label>
            <span class="search-label">поиск города</span>
          </template>
          <template v-slot:append-inner>
            <browser-location
              class="pointer"
              @geo_city_found="geoCityFound"
              @disabled_input="disabledInputHandler"
              aria-label="Определить город"
            />
          </template>
        </v-text-field>
      </v-card-text>
    </div>
    <v-card-text id="list_wrapper" class="overflow-y-auto city-card">
      <div class="error-container h-100 d-flex justify-center align-center" v-if="showErrorMessage">
        <div class="error">{{ errorMessage }}</div>
      </div>
      <v-list
        @mousedown.prevent.stop
        v-else
        class="pb-6 pt-6 list-container city"
        bg-color="transparent"
        role="main"
      >
        <v-list-item
          class="parent"
          v-for="city in filteredCities"
          :key="city.id"
          :class="city.cities && city.cities.length > 0 ? 'has-child' : ''"
          variant="text"
          min-height="20"
          density="compact"
        >
          <v-list-item
            class="parent-title text-left text-subtitle-1 font-weight-bold text-no-wrap"
            density="compact"
            variant="text"
            :href="addHttpPrefix(city.url)"
            @click="
              useAnalytics({
                action: 'city-select',
                category: 'category',
                label: 'label',
                value: city.city
              })
            "
          >
            {{ city.city }}
          </v-list-item>

          <v-list
            v-if="city.cities && city.cities.length > 0"
            class="city_child child"
            bg-color="transparent"
          >
            <div class="city-child-wrap">
              <v-list-item
                class="text-left text-subtitle-2 font-weight-normal text-no-wrap"
                v-for="childCity in city.cities"
                :key="childCity.id"
                variant="text"
                min-height="20"
                density="compact"
                :href="addHttpPrefix(childCity.url)"
              >
                <p class="child_city">{{ childCity.city }}</p>
              </v-list-item>
            </div>
          </v-list>
        </v-list-item>
      </v-list>
    </v-card-text>
    <div class="d-flex justify-center align-center footer position-relative pa-4">
      <div class="d-flex justify-start footer-button">
        <toggle-theme
          @click="
            useAnalytics({
              action: 'theme-toggle',
              category: 'category',
              label: 'label',
              value: '123'
            })
          "
          aria-label="Изменить тему оформления"
        />
      </div>
      <div class="flex-1-1 text-medium-emphasis">
        <span class="for-lm">{{ copyright }}</span>
        <span class="for-sm">{{ copyright_short }}</span>
      </div>
      <div class="d-flex justify-end footer-button">
        <v-btn
          id="phone"
          class=""
          rounded="xl"
          variant="plain"
          :href="`tel:${phone_filtered}`"
          @click="
            useAnalytics({
              action: 'phone',
              category: 'category',
              label: 'label',
              value: '123'
            })
          "
          aria-label="Позвонить"
        >
          <v-icon color="logo-color">$phone</v-icon>
          <span class="for-lm pl-2">{{ phone }}</span>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import type { GeoSubdivision } from '@/shared/api/geo_subdivision/types'
import { getGeoSubdivisions } from '@/shared/api/geo_subdivision/GetGeoSubdivisions'
import {
  getLocalStorageCities,
  setLocalStorageCities
} from '@/shared/api/local_storage/storageCities'
import BrowserLocation from '@/shared/client/components/BrowserLocation.vue'
import LogoKassy from '@/shared/client/components/LogoKassy.vue'
import ToggleTheme from '@/shared/client/components/ToggleTheme.vue'
import { useAnalytics } from '@/shared/helpers/useAnalytics'
import { computed, onMounted, ref } from 'vue'
import { browser } from '@/main'

const copyright_short = import.meta.env.KASSY_COPYRIGHT_SHORT
const copyright = import.meta.env.KASSY_COPYRIGHT
const phone = import.meta.env.KASSY_PHONE
const phone_filtered = import.meta.env.KASSY_PHONE_FILTERED

const search = ref('')
const cities = ref<GeoSubdivision[]>([])
const showErrorMessage = ref(false)
const inputDisabled = ref(false)
const errorMessage = ref('')

const disabledInputHandler = (isLoading) => {
  inputDisabled.value = isLoading
}

const geoCityFound = (city: string) => {
  showErrorMessage.value = false
  search.value = city

  useAnalytics({
    action: 'city-search-auto',
    category: 'category',
    label: 'label',
    value: search.value
  })
  inputDisabled.value = false
}

/*const geoCityNotFound = (coords: { geo_lat: number; geo_lng: number }) => {
  console.log('geo_city_not_found', coords)
}*/

const handleApiError = () => {
  showErrorMessage.value = true
  errorMessage.value = 'Произошла ошибка, скоро починим:('
}

const urlParams = new URLSearchParams(window.location.search)

const searchParam = urlParams.get('search')

const filteredCities = computed(() => {
  if (search.value) {
    return cities.value.filter((city) =>
      city.keywords.toLowerCase().includes(search.value.toLowerCase())
    )
  } else {
    return cities.value
  }
})

const scrollWrapToTop = () => {
  const list_wrapper = document.getElementById('list_wrapper')
  if (list_wrapper) {
    list_wrapper.scrollTop = 0
  }
}

onMounted(async () => {
  const localStorageCities = getLocalStorageCities()
  if (localStorageCities && localStorageCities.expire > Date.now()) {
    cities.value = localStorageCities.cities
  } else {
    try {
      const data = await getGeoSubdivisions(
        import.meta.env.KASSY_API_HOST,
        import.meta.env.KASSY_API_AGENT_ID,
        import.meta.env.KASSY_API_SECRET_KEY
      )
      cities.value = data.content

      setLocalStorageCities({
        cities: cities.value,
        expire: new Date().setHours(new Date().getHours() + 4)
      })
    } catch (error) {
      handleApiError()
    }
  }
  if (searchParam) {
    search.value = searchParam
    checkNotFound()
  }

  const list_container = document.getElementById('list_wrapper')
  if (list_container) {
    browser?.name !== 'firefox' ? list_container.classList.add('custom_scrollbar') : null
  }
})

const addHttpPrefix = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`
  }
  return url
}

const clearCity = () => {
  search.value = ''
  if (showErrorMessage.value) {
    showErrorMessage.value = false
  }
}

const checkNotFound = () => {
  showErrorMessage.value = filteredCities.value.length === 0
  errorMessage.value = 'Город не найден :('
}
</script>

<style scoped>
.logo-kassy {
  position: relative;
  width: 110px;
  top: -7px;
  margin-right: 10px;
}

.search_wrap {
  width: 100%;
  z-index: 99;
  opacity: 0.95;
}

.search {
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px 20px 0 20px;
}

.search-label {
  margin-left: 12px;
}

.v-field {
  display: flex !important;
  align-items: center !important;
}

.v-label {
  margin-top: 3px;
  align-items: center !important;
}

.v-list-item {
  break-inside: avoid-column;
  border-radius: 8px;
}

@media (pointer: coarse) {
  .custom_scrollbar::-webkit-scrollbar-thumb {
    background-color: #8f4ae0 !important;
    border-radius: 12px !important;
  }
}

.v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  position: relative !important;
  top: -2px !important;
}

/* патч для safari - иначе при наведении на поиск логотип дергается */
.v-field__outline {
  box-sizing: content-box;
}

.notfound-container {
  width: 100%;
  max-width: 1000px;
}

.error {
  width: auto;
  font-size: 2rem;
  font-weight: 100;
}

.city-card {
  scroll-behavior: smooth;
  touch-action: none;
  margin-left: 6px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.custom_scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #f7f7f7 #8f4ae0;
}

.custom_scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.custom_scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #8f4ae0;
  border-radius: 12px;
}

.list-container {
  column-count: 3;
}

@media screen and (max-width: 600px) {
  .list-container {
    column-count: 1;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .list-container {
    column-count: 2;
  }
}

@media screen and (min-width: 960px) {
  .list-container {
    column-count: 3;
  }
}

.v-list-item.parent {
  padding: 0 !important;
  margin: 0 !important;
}

.v-list-item.parent.has-child {
  margin-bottom: 10px !important;
}

.v-list-item.parent.has-child .city_child {
  border: solid 1px transparent;
}

.v-list-item.parent.has-child:hover .city_child {
  border-left: solid 1px rgb(var(--v-theme-surface-city-card));
  border-right: solid 1px rgb(var(--v-theme-surface-city-card));
  border-bottom: solid 1px rgb(var(--v-theme-surface-city-card));
  border-radius: 0 0 8px 8px;
}

.v-list-item.parent:hover .parent-title {
  background: rgba(var(--v-theme-surface-city-card), var(--v-high-emphasis-opacity));
}

.parent-title {
  padding: 0;
  border-radius: 8px;
  min-height: 35px !important;
}

.parent-title:hover {
  background-color: #8f4ae0 !important;
  color: #fff;
  border-radius: 8px !important;
}

.child {
  margin: 0 7px;
  border-radius: 10px;
  padding: 6px 0;
}

.city-child-wrap {
  margin: 0 6px;
}

.child .v-list-item:hover {
  background-color: #8f4ae0 !important;
  color: #fff;
}
</style>

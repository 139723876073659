<template>
  <div id="app-container" class="d-flex flex-column text-center page-container">
    <MainSection />
  </div>
</template>

<script setup lang="ts">
import MainSection from '@/shared/client/MainSection.vue'

console.log('running in', import.meta.env.MODE, 'ver.', import.meta.env.KASSY_APP_VERSION)
</script>

import type { CityFlatten } from '@/shared/api/local_storage/types'

const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  // console.log(`Coordinates: ${lat1}, ${lon1}, ${lat2}, ${lon2}`)
  const R = 6371 // радиус зем в км
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const findNearestCity = (
  userCoords: { geo_lat: number; geo_lng: number },
  cities: CityFlatten[],
  threshold: number
): CityFlatten | null => {
  let minDistance = Infinity
  let nearestCity: CityFlatten | null = null

  cities.forEach((city) => {
    const distance = calculateDistance(
      userCoords.geo_lat,
      userCoords.geo_lng,
      city.geo_lat,
      city.geo_lng
    )
    if (distance <= threshold) {
      if (distance < minDistance) {
        minDistance = distance
        nearestCity = city
      }
    }
  })
  return nearestCity
}

<template>
  <div>
    <v-btn
      class="icon-map"
      variant="text"
      icon="$mapMarker"
      color="primary"
      size="x-middle"
      :ripple="false"
      @mousedown.stop
      @click.prevent.stop="
        // prettier-ignore
        getLocation();
        handlerLoading()
      "
      :loading="isLoading"
    >
    </v-btn>
    <snack-bar v-model="snackBarOptions.show" :text="snackBarOptions.text"></snack-bar>
  </div>
</template>

<script setup lang="ts">
import { getCitiesFlatten } from '@/shared/api/local_storage/storageCities'
import { findNearestCity } from '@/shared/helpers/geoLocationCalculate'
import { ref } from 'vue'
import SnackBar from '@/shared/client/components/SnackBar.vue'

type UserCoords = {
  geo_lat: number
  geo_lng: number
}

const snackBarOptions = ref({
  show: false,
  text: ''
})

const emit = defineEmits(['geo_city_found', 'geo_city_not_found', 'disabled_input'])
const locationData = ref<GeolocationPosition>()
const isLoading = ref(false)

const handlerLoading = () => {
  isLoading.value = true
  emit('disabled_input', isLoading.value)
}

const getLocation = () => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        locationData.value = position
        const userCoords: UserCoords = {
          geo_lat: position.coords.latitude,
          geo_lng: position.coords.longitude
        }
        const thresholdDistance = import.meta.env.KASSY_GEO_THRESHOLD_DISTANCE

        // для проверки ошибки
        // userCoords.geo_lng = 333
        // userCoords.geo_lat = 333
        // console.log('Получаем позицию на основе геолокации браузера:', userCoords)

        const foundNearestCity = findNearestCity(userCoords, getCitiesFlatten(), thresholdDistance)
        if (foundNearestCity !== null) {
          emit('geo_city_found', foundNearestCity.city)

          //для проверки
          //  setTimeout(() => isLoading.value = false, 3000)
          isLoading.value = false
        } else {
          // emit('geo_city_not_found', userCoords)
          //для проверки
          //  setTimeout(() => isLoading.value = false, 3000)
          isLoading.value = false
          emit('disabled_input', isLoading.value)
          snackBarOptions.value.show = true
          snackBarOptions.value.text = 'Мы не смогли определить ваш город :('
        }
      },
      () => {
        //для проверки

        // setTimeout(() => {
        //   isLoading.value = false
        //   emit('disabled_input', isLoading.value);
        //   console.log('time', )
        // }, 5000)

        isLoading.value = false
        emit('disabled_input', isLoading.value)
        snackBarOptions.value.show = true
        snackBarOptions.value.text = 'Разрешите определение геолокации в настройках браузера.'
      }
    )
  } else {
    //  setTimeout(() => isLoading.value = false, 3000)
    isLoading.value = false
    emit('disabled_input', isLoading.value)
    snackBarOptions.value.show = true
    snackBarOptions.value.text = 'Ваш браузер не может определить геолокацию.'
  }
}
</script>

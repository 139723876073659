import type { ThemeDefinition } from './types'

export const kassyLightTheme: ThemeDefinition = {
  dark: false,
  variables: {},
  colors: {
    primary: '#8f4ae0',
    secondary: '#03DAC6', // ???
    error: '#c02f3c',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',

    'background-gradient-1': '#FFFFFF',
    'background-gradient-2': '#DDDDDD',

    background: '#000', // ???
    'on-background': '#333', // текст футера

    surface: '#fff',
    'surface-variant': '#ccc', // бордер у футера
    'on-surface': '#333', // основной цвет текста
    accent: '#000',

    'border-color': '#fff',
    'primary-darken-1': '#3700B3', // ??
    'secondary-darken-1': '#018786', // ??
    'logo-color': '#000',
    'surface-city-card': 'rgb(211,211,211)'
  }
}

export const kassyDarkTheme: ThemeDefinition = {
  dark: true,
  variables: {},
  colors: {
    primary: '#8f4ae0',
    background: '#fff',
    surface: '#121212',

    'background-gradient-1': '#1C1226FF',
    'background-gradient-2': '#0F0A14FF',

    accent: '#000',
    secondary: '#03DAC6',
    error: '#640119',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',

    'border-color': '#fff',
    'surface-variant': '#333',
    'on-surface': '#aaa',
    'on-background': '#aaa',

    'primary-darken-1': '#3700B3',
    'secondary-darken-1': '#018786',
    'logo-color': '#fff',
    'surface-city-card': '#25223b'
  }
}

import axios from 'axios'
import { MD5 } from 'crypto-js'

export const sendRequest = async (url: string, xml: string, sign: string) => {
  const formData = new FormData()
  formData.append('xml', xml)
  formData.append('sign', sign)
  return await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const calculateSign = (xml: string, secretKey: string) => {
  return MD5(xml + secretKey).toString()
}
